<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <div class="content">
    <router-view />
  </div>
</template>

<style>
@font-face {
  font-family: "Butler Stencil";
  src: url("/public/fonts/ButlerStencil-UltraLight.woff2") format("woff2"),
    url("/public/fonts/ButlerStencil-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("/public/fonts/ButlerStencil-Medium.woff2") format("woff2"),
    url("/public/fonts/ButlerStencil-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("/public/fonts/ButlerStencil.woff2") format("woff2"),
    url("/public/fonts/ButlerStencil.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GothamMedium";
  src: url("/public/fonts/GothamMedium.woff2") format("woff2"),
    url("/public/fonts/GothamMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  border: 0;
  margin: 0;
  padding: 0;
}
body {
  background: #ffe5b4;
  max-width: 100vw;
}
.content {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
* {
  box-sizing: border-box;
  font-family: 'Butler Stencil', serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

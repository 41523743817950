<template>
  <div class="footer-main">
    <div style="margin-bottom: 20px;">
      <img class="footer__img--logo" :src="`images/Margo_W_white.png`"></div>
    <div>
      &copy;<span class="year"></span> margowilliams.co.uk
    </div>
  </div>
</template>

<script>

export default {
  name: "AboutNew",
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    year() {
      const d = new Date();
      let year = d.getFullYear();
      document.querySelector(".year").innerHTML = year;
    }
  },
  mounted() {
    this.year();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer-main {
  background: #000000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 25px;
  & > div {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
}
.footer__img--logo {
  width: 200px;
}
.year {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}
</style>

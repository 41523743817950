<template>
  <div class="gallery">
    <div v-for="post in posts" class="gallery-item" :key="post._id">
      <img
        :src="
          'https://cdn.sanity.io/images/af3agose/production/' +
          post.image.asset._ref.replace('image-', '').replace('-jpg', '.jpg')
        "
      />
    </div>
  </div>
  <div class="gallery--2">
    <div v-for="post in posts_2" class="gallery-item" :key="post._id">
      <img
        :src="
          'https://cdn.sanity.io/images/af3agose/production/' +
          post.image.asset._ref.replace('image-', '').replace('-jpg', '.jpg')
        "
      />
    </div>
  </div>
</template>

<script>
import sanity from "../client";

const query = `*[_type == "initialImages"] | order(position asc) {
  image,
  publishedAt
}[0...50]`;
const query_2 = `*[_type == "secondaryImages"] | order(position asc) {
  image,
  publishedAt
}[0...50]`;

export default {
  name: "AboutNew",
  data() {
    return {
      loading: true,
      posts: [],
      posts_2: [],
    };
  },
  created() {
    this.fetchData();
    this.fetchData_2();
  },
  methods: {
    fetchData() {
      this.error = this.about = null;
      this.loading = true;
      sanity.fetch(query).then(
        (posts) => {
          this.loading = false;
          this.posts = posts;
        },
        (error) => {
          this.error = error;
          alert(error);
        }
      );
    },
    fetchData_2() {
      this.error = this.about = null;
      this.loading = true;
      sanity.fetch(query_2).then(
        (posts) => {
          this.loading = false;
          this.posts_2 = posts;
        },
        (error) => {
          this.error = error;
          alert(error);
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gallery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1280px;
  overflow: hidden;
  padding-top: 50px;
  width: 100%;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
  }
  .gallery-item {
    width: calc((100% - 75px) / 4);
    @media screen and (max-width: 1024px) {
      margin-bottom: 25px;
      width: calc(50% - 12.5px);
    }
  }
  img {
    display: block;
    width: 100%;
  }
}
.gallery--2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1280px;
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .gallery-item {
    width: calc((100% - 25px) / 2);
    @media screen and (max-width: 1024px) {
      margin-bottom: 25px;
      width: 100%;
    }
  }
  img {
    display: block;
    width: 100%;
  }
}
</style>

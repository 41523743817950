<template>
  <div class="about">
    <div class="loading" v-if="loading">Loading...</div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div></div>
    <div class="about__wrapper">
      <div class="about__2-col">
        <h6>I AM</h6>
        <div><img class="about__img--logo" :src="`images/Margo_W_black.png`"></div>
      </div>
      <div class="about__2-col">
        <div v-for="post in posts" class="post-item" :key="post._id">
          <h2>{{ post.name }}</h2>
          <p>{{ post.details }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";

const query = `*[_type == "about"] | order(position asc) {
  _id,
  name,
  details
}[0...50]`;

export default {
  name: "AboutNew",
  data() {
    return {
      loading: true,
      posts: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.about = null;
      this.loading = true;
      sanity.fetch(query).then(
        (posts) => {
          this.loading = false;
          this.posts = posts;
        },
        (error) => {
          this.error = error;
          alert(error);
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  overflow: hidden;
  padding: 75px 25px 50px 25px;
  width: 100%;
}
.about__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-width: 1280px;
  width: 100%;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}
.about__2-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: calc(50% - 25px);
  @media screen and (max-width: 700px) {
    font-size: 10vw;
    line-height: 10vw;
    width: 100%;
  }

  & > div {
    margin-bottom: 20px;
    padding-top: 10px;
    width: 100%;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
}
h6 {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 300px) {
    font-size: 18vw;
    line-height: 18vw;
  }
  @media screen and (min-width: 700px) {
    font-size: 10vw;
    line-height: 10vw;
  }
  @media screen and (min-width: 1280px) {
    font-size: 4rem;
    line-height: 4rem;
  }
}
h2 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}
p {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.about__img--logo {
  width: 100%;
}
</style>

<template>
  <div class="hero">
    
    <div v-for="post in posts" class="post-item" :key="post._id" :style="windowWidth > 700 ? {background: `url(https://cdn.sanity.io/images/af3agose/production/${post.image.asset._ref.replace('image-', '').replace('-jpg', '.jpg')})`, backgroundSize: 'cover', width: '100%', height: '100%', backgroundPosition: 'center'} : {background: `url(https://cdn.sanity.io/images/af3agose/production/${post.mobileImage.asset._ref.replace('image-', '').replace('-jpg', '.jpg')})`, backgroundSize: 'cover', width: '100%', height: '100%', backgroundPosition: 'center'}">
      <div class="hero__wash">
        <img class="hero__img--logo" :src="!post.color ? `images/Margo_W_white.png` : `images/Margo_W_black.png`">
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";

const query = `*[_type == "hero"] | order(position asc) {
  image,
  mobileImage,
  color
}[0...50]`;

export default {
  name: "HelloWorld",
  data() {
    return {
      loading: true,
      windowWidth: window.innerWidth,
      posts: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.hero = null;
      this.loading = true;
      sanity.fetch(query).then(
        (posts) => {
          this.loading = false;
          this.posts = posts;
          console.log(this.posts);
        },
        (error) => {
          this.error = error;
          alert(error);
        }
      );
    },
  },
  mounted() {
    var ref = this;
    window.addEventListener('resize', function() {
      ref.windowWidth = window.innerWidth
    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero {
  background: #FFe5B4;
  height: calc(100vh - 100px);
  min-height: 600px;
  overflow: hidden;
  width: 100%;
}
.hero__wash {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.hero__img--logo {
  max-width: 1200px;
  width: calc(100% - 100px);
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
</style>

<template>
  <div class="home">
    <Hero />
    <Gallery />
    <About />
    <FooterGallery />
    <FooterMain />
   
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import About from "@/components/About.vue";
import Gallery from "@/components/Gallery.vue";
import FooterGallery from "@/components/FooterGallery.vue";
import FooterMain from "@/components/FooterMain.vue";


export default {
  components: {
    Hero, About, Gallery, FooterMain, FooterGallery
  },
  name: "HomeNew",
  data() {
    return {
      loading: true,
    };
  },
  created() {
    
  },
  methods: {
   
  },
};
</script>

<style scoped>
.home h1{
  text-align: center;
}
.container {
  margin: 0 auto;
  max-width: 42em;
  width: 100%;
}
.post-item {
  box-sizing: border-box;
}
</style>